import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

const routes: Routes = [
  // {
  //   loadChildren: () => import('./landing-page/landing-page.module').then(m => m.LandingPageModule),
  //   path: ''
  // },
  {
    loadChildren: () => import('./home-page/home-page.module').then(m => m.HomePageModule),
    path: ''
  },
  {
    loadChildren: () => import('./layout/layout.module').then(m => m.LayoutModule),
    path: ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking'
})],
  exports: [RouterModule]
})

export class AppRoutingModule {
}
