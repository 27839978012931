import * as Sentry from '@sentry/browser';
import {ErrorDialogComponent} from './error-dialog/error-dialog.component';
import {ErrorHandler, Injectable, NgZone} from '@angular/core';
import {LoadingService} from '../shared/services/loading.service';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {environment} from '../../environments/environment';

Sentry.init({
  dsn: 'https://85ebd176b0734eabba0879b479d01704@o601432.ingest.sentry.io/4505080682905600',
  integrations: [new Sentry.Replay()],
  replaysOnErrorSampleRate: 1.0,
  replaysSessionSampleRate: 0.05
});

@Injectable()
export class AppErrorHandler implements ErrorHandler {
  constructor(private loadingService: LoadingService,
              private matDialog: MatDialog,
              private ngZone: NgZone) {
  }

  handleError(error: any): void {
    if (environment.production) {
      Sentry.captureException(error.originalError || error);
    } else {
      console.log(error);
    }
    this.loadingService.hide();
    // this.ngZone.run(() => {
    //   this.matDialog.open(ErrorDialogComponent, {
    //     data: {
    //       message: 'Παρουσιάστηκε κάποιο σφάλμα κατά την εκτέλεση κάποιας διεργασίας του συστήματος.',
    //       title: 'Ειδοποίηση Συστήματος'
    //     }
    //   });
    // });
  }
}
