import {Component, OnInit} from '@angular/core';
import {LoadingService} from './shared/services/loading.service';
import {NavigationStart, Router} from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  public loading: boolean;

  constructor(private loadingService: LoadingService,
              private router: Router) {
    this.loading = false;

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        window.scrollTo(0, 0);
      }
    });
  }

  public ngOnInit(): void {
    this.loadingService.events.subscribe((event) => {
      setTimeout(() => {
        this.loading = (event === 'show');
      });
    });
  }
}
